import React from 'react'
import BadGetawayZ from '../components/BadGetaway'

const BadGetawayError = () => {
  return (
    <div>
        <BadGetawayZ/>
    </div>
  )
}

export default BadGetawayError
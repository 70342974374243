import React from 'react'
import BadGetaway from '../Assets/BadGetaway.png'

const BadGetawayZ = () => {
  return (
    <div className="absolute insert-0 flex items-center justify-center rounded-full h-full w-full bg-opacity-50 bg-slate-700 text-slate-200">
    <BadGetaway className='mr-2'/>
      Oops! Sorry! Bad Getaway...
    </div>
  )
}

export default BadGetawayZ

import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  HopePage,
  LoginPage,
  SignupPage,
  BadGetawayError,
  ActivationPage,
  ProductsPage,
  ProductDetailsPage,
  FAQPage
} from "./Routes.js";
import Navbar from "./components/Navbar.jsx";
//import { ToastContainer } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
//import axios from "axios"; ----------------this
//import { server } from "./server.js"; -----and this are not needed after redux is being used
import Store from "./redux/store.js";
import { loadUser } from "./redux/actions/user.js";

const App = () => {
  useEffect(() => {
   Store.dispatch(loadUser());
  }, []);
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HopePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignupPage />} />
        <Route
          path="/activation/:activation_token"
          element={<ActivationPage />}
        />

        <Route path="/products" element={<ProductsPage />} />
        <Route path="/product/:name" element={<ProductDetailsPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route component={<BadGetawayError />} />
      </Routes>
      {/*
       <ToastContainer
       position="bottom-center"
       autoClose={5000}
       hideProgressBar={false}
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="dark"
       />
  */}
    </BrowserRouter>
  );
};

export default App;

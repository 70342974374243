import React from "react";
import Activation from "../components/Signup/Activation";

const ActivationPage = () => {
  return (
    <div>
      <Activation />
    </div>
  );
};

export default ActivationPage;

//working redo:
import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
//import { RxAvatar } from "react-icons/rx";
import DefaultAvatar from '../../Assets/DefaultAvatar.png';
import axios from "axios";
import { server } from "../../server";
import { HiOutlineUpload } from "react-icons/hi";
//import { toast } from "react-toastify";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [showText, setShowText] = useState(false);
  const navigate = useNavigate();

  const handleFileInputChange = (e) => {
    console.log("handleFileInputChange yo"); // checkPoint
    const file = e.target.files[0];

    setAvatar(file);
    console.log(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(" yo handle submit"); // checkPoint

    const config = { headers: { "Content-type": "multipart/form-data" } };
    const newForm = new FormData();

    newForm.append("file", avatar);
    newForm.append("name", name);
    newForm.append("email", email);
    newForm.append("password", password);

    axios
      .post(`${server}/user/create-user`, newForm, config)
      .then((res) => {
        console.log(res);
        console.log("oi upload data SUCCESS!");
        //toast.success(res.data.message);
        setName("");
        setEmail("");
        setPassword("");
        setAvatar();
        navigate("/activation/:activation_token");
        //alert(res.message);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        alert(error.response.data.message);
        //toast.error(error.response.data.message);
      });
  };

  return (
    <div className="min-h-screen bg-slate-400 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-slate-950">
          Create an account
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-slate-300 py-8 px-4 shadow sm:rounded-lg sm:px-9">
          <form className="space-y-7" onSubmit={handleSubmit}>
            <div>
              <div
                className="items-center cursor-pointer relative flex"
                onMouseLeave={() => setShowText(false)}
                onMouseEnter={() => setShowText(true)}
                onClick={() => document.getElementById("file-input").click()}
              >
                <span htmlFor="avatar" className="h-28 w-28 flex rounded-full overflow-hidden">
                  {avatar ? (
                    <img
                      src={URL.createObjectURL(avatar)}
                      alt="avatar"
                      className="h-28 w-28 flex object-cover"
                    />
                  ) : (
                    <img src={DefaultAvatar} alt="Default Avatar" />
                  )}
                </span>
                {showText && (
                  <div className="absolute insert-0 flex items-center justify-center rounded-full h-28 w-28 bg-opacity-50 bg-slate-700 text-slate-200">
                    <HiOutlineUpload className="mr-2" /> Upload
                  </div>
                )}
                <label htmlFor="file-input" className="ml-5 flex">
                  <input
                    type="file"
                    name="avatar"
                    id="file-input"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleFileInputChange}
                    className="sr-only"
                  />
                </label>
              </div>
            </div>

            <div>
              <label
                htmlFor="name-input"
                className="block text-sm font-medium text-gray-700"
              >
                Full Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="text"
                  id="name-input" // Added id attribute
                  autoComplete="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-slate-900 focus:outline-none focus:ring-blue-300 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email-input"
                className="block text-sm font-medium text-slate-500"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  id="email-input"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-slate-900 focus:outline-none focus:ring-blue-300 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password-input"
                className="block text-sm font-medium text-slate-500"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  id="password-input"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-slate-700 focus:outline-none focus:ring-slate-600 focus:border-slate-300 sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="gtoup relative w-full h-[40px] flex justify-center py-2 px-4 borrder border-transparent text-sm font-medium rounded-md text-yellow-50 bg-slate-700 hover:bg-slate-500"
              >
                Submit
              </button>
            </div>
            <div className={`${styles.noramlFlex} w-full`}>
              <Link to="/login" className="text-blue-600 pr-2">
                Sign In
              </Link>
              <h4>to an existing account.</h4>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;

import React from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
  return (
    <nav className='bg-slate-700 text-slate-500 '>
        <ul >
            <li>
                <NavLink exact="true" className='active:text-slate-300 focus:text-slate-400' to = "/">Home</NavLink>
            </li>
            <li>
                <NavLink className='active:text-slate-300 focus:text-slate-400' to = "/Login">Login</NavLink>
            </li>
            <li>
                <NavLink className='active:text-slate-300 focus:text-slate-400' to = "/sign-up">Signup</NavLink>
            </li>
        </ul>
      
    </nav>
  )
}

export default Navbar

import React from 'react'

const Home = () => {
  return (
    <div className='min-h-screen bg-slate-400 flex flex-col justify-center py-12 sm:px-7 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <h2 className='mt-6 text-center text-3xl font-extrabold text-slate-950'>
          Home Sweet Home 3D!
        </h2>
      </div>
    </div>
  )
}

export default Home

import React, { useEffect, useState } from "react";
import axios from "axios";
//import Activation from "../components/Signup/Activation"
import { useParams } from "react-router-dom";
import { server } from "../../server";

const Activation = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(false);
  const [emailChecked, setEmailChecked] = useState(
    localStorage.getItem("emailChecked") === "true" // Initialize from localStorage
  ); // New state to track email check

  useEffect(() => {
    if (activation_token) {
      const activationEmail = async () => {
        try {
          const res = await axios.post(`${server}/user/activation`, {
            activation_token,
          });
          console.log(res.data.messsage);
          setEmailChecked(true); // Set emailChecked to true after successful activation
          localStorage.setItem("emailChecked", "true"); // Save to localStorage
        } catch (error) {
          console.log(error.response.data.messsage);
          setError(true);
        }
      };
      activationEmail();
    }
  }, [activation_token]);
  return (
    <div className='min-h-screen bg-slate-400 flex flex-col justify-center py-12 sm:px-7 lg:px-8'>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {!emailChecked ? ( // Show "Check email" message if email is not checked
          <p className="mt-6 text-center text-3xl text-slate-700">
            Check your email to activate your account!
          </p>
        ) : error ? (
          <p className="mt-6 text-center text-3xl text-rose-700">
            Token Expired
          </p>
        ) : (
          <p className="mt-6 text-center text-3xl font-extrabold text-emerald-300">
            Your account is ready!
            <br />
            Login to continue!
          </p>
        )}
      </div>
    </div>
  );
};

export default Activation;
